import { Routes } from '@angular/router';

import { PlayerComponent } from './player.component';

export const playerRoute: Routes = [
    {
        path: 'player/:id',
        component: PlayerComponent,
        data: {
          pageTitle: 'voxxedApp.event.home.title'
        }
    }
];

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Event } from './event.model';
import { EventService } from './event.service';
import { WindowRef } from '../../home/WindowRef';

@Component({
    selector: 'app-event-detail',
    templateUrl: './event-detail.component.html'
})
export class EventDetailComponent implements OnInit {

    event: Event;
    futureEvents: Event[];
    nextEvent: Event;

    constructor(
        private eventService: EventService,
        private route: ActivatedRoute,
        private winRef: WindowRef
    ) {
    }

    ngOnInit() {
        this.winRef.nativeWindow.webFlow.destroy();
        this.winRef.nativeWindow.webFlow.ready();

        this.loadFutureEvents();

        this.route.params.subscribe((params) => {
          const ID = 'id';
          this.load(params[ID]);
        });
    }

    load(id) {
        this.eventService.find(id).subscribe(res => {
            this.event = res.body;
            this.getNextEventId();
        }, res => this.onError(res.json));
    }

    loadFutureEvents() {
        this.eventService.futureDevoxxEvents().subscribe( res => {
            this.futureEvents = res.body;
            this.getNextEventId();
        }, res => this.onError(res.json));
    }

    getNextEventId() {
        if (this.futureEvents != null &&
            this.event != null) {
            const foundEvent = this.futureEvents.find( x => x.id === this.event.id );
            let index = this.futureEvents.indexOf(foundEvent);
            index++;
            if (index >= this.futureEvents.length) {
                this.nextEvent = this.futureEvents[0];
            } else {
                this.nextEvent = this.futureEvents[index];
            }
        }
    }

    isNotPastEvent() {
        if (this.event != null &&
            this.event.toDate != null) {
            return new Date().getTime() < this.event.toDate.getTime();
        }
        return false;
    }

    getFromDate() {
        if (this.event != null &&
            this.event.fromDate != null &&
            this.event.toDate != null) {

            const fromDate = this.event.fromDate;
            const endDate = this.event.toDate;

            const options = {year: 'numeric', month: 'long', day: 'numeric'};

            if (fromDate.getTime() === endDate.getTime()) {
                return fromDate.toLocaleDateString(fromDate.getTimezoneOffset(), options);
            } else {
                const fromDateOptions = {day: 'numeric'};
                return fromDate.toLocaleDateString(fromDate.getTimezoneOffset(), fromDateOptions) + ' - ' +
                       endDate.toLocaleDateString(fromDate.getTimezoneOffset(), options);
            }
        }
    }

    getNextEventImageURL(event: Event) {
        if (event != null && event.imageURL != null) {
            const strings = event.imageURL.split('.jpg');
            return strings[0] + '_thumbnail.jpg';
        }
    }

    private onError(error) {
      console.log(error);
    }
}

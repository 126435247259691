<a href="#/speaker/{{speaker?.id}}" class="team-image-link-block w-inline-block">
    <div data-ix="blog-image-zoom" class="category-overlay">
        <div class="category-title-wrapper w-clearfix">
            <div class="team-member-name">{{speaker?.firstName}} {{speaker?.lastName}}</div>
            <div class="team-member-job-title">{{speaker?.company}}</div>
            <div class="read-more-button small"><img src="https://s3-eu-west-1.amazonaws.com/devoxxians/images/app/Icon-arrow-right-white_1.png" class="read-more-arrow" alt="More details"></div>
        </div>
    </div>
    <div class="blog-image-block" [style.background-image]="'url(' + getSpeakerAvatarURL() + ')'"></div>
</a>

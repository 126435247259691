<div data-collapse="all"
     data-animation="over-right"
     data-duration="400"
     data-doc-height="1"
     data-no-scroll="1"
     class="navbar2 w-nav">
  <div class="container w-container">
    <a [routerLink]="['/']" class="logo-block w-nav-brand w--current">
      <div class="text-block">VoxxedDays</div>
      <div class="text-block-7">From developers, For developers</div>
    </a>
    <nav role="navigation" class="nav-menu w-nav-menu">
      <div class="nav-color-overlay">
        <a [routerLink]="['/']" class="nav-link">HOME</a>
        <a [routerLink]="['../events']" class="nav-link">EVENTS</a>
        <a [routerLink]="['../speakers']" class="nav-link">CHAMPIONS</a>
        <a href="https://beta.voxxed.com" class="nav-link">PRESENTATIONS</a>
        <a [routerLink]="['../manifesto']" class="nav-link">MANIFESTO</a>
        <a [routerLink]="['../about']" class="nav-link">ABOUT US</a>
        <a [routerLink]="['../contact']" class="nav-link">CONTACT</a>
        <a href="https://gitlab.com/voxxed/VoxxedDaysWebsite/issues/new" class="nav-link">REPORT ISSUE</a>
        <div class="nav-content-block">
          <div class="nav-block-title">About <strong>Voxxed Days</strong></div>
          <p class="nav-text">Voxxed Days is a series of tech events organised by local community groups and
            supported by the Voxxed team. Sharing the Devoxx philosophy that content comes first,
            these events see both internationally renowned and local speakers converge at a wide range of
            locations around the world.</p>
          <a [routerLink]="['../about']" class="nav-read-more-link">More About Us</a></div>
      </div>
    </nav>
    <div class="menu-button w-nav-button">
      <div class="w-icon-nav-menu"></div>
    </div>
  </div>
  <div class="w-nav-overlay" data-wf-ignore=""></div>
</div>

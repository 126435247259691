import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import { VoxxedDaysSharedLibsModule} from './shared-libs.module';
import { VoxxedDaysSharedCommonModule} from './shared-common.module';

@NgModule({
    imports: [
      VoxxedDaysSharedLibsModule,
      VoxxedDaysSharedCommonModule
    ],
    declarations: [

    ],
    providers: [
        DatePipe
    ],
    exports: [
        VoxxedDaysSharedCommonModule,
        DatePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class VoxxedDaysSharedModule {}

import { NgModule, LOCALE_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { EventItemComponent } from './evenItem/eventItem.component';
import { SpeakerItemComponent } from './speakerItem/speakerItem.component';
import { VoxxedDaysSharedLibsModule } from './shared-libs.module';

@NgModule({
    imports: [
      VoxxedDaysSharedLibsModule
    ],
    declarations: [
      EventItemComponent,
      SpeakerItemComponent,
    ],
    providers: [
        Title,
        {
            provide: LOCALE_ID,
            useValue: 'en'
        },
    ],
    exports: [
      VoxxedDaysSharedLibsModule,
      EventItemComponent,
      SpeakerItemComponent,
    ]
})
export class VoxxedDaysSharedCommonModule {}

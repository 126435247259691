import { Component, OnInit } from '@angular/core';

import { EventService, Event } from '../entities/event';
import { SpeakerService } from '../entities/speaker';
import { WindowRef } from './WindowRef';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

    futureEvents: Event[];
    pastEvents: Event[];
    loading: boolean;

    constructor(
        private eventService: EventService,
        private speakerService: SpeakerService,
        private winRef: WindowRef
    ) {
    }

    ngOnInit() {
        this.winRef.nativeWindow.webFlow.destroy();
        this.winRef.nativeWindow.webFlow.ready();

        this.loading = true;
        this.loadPastEvents();
        this.loadFutureEvents();
    }

    isLoading() {
        return this.loading;
    }

    loadPastEvents() {
        this.eventService.pastEvents({ size: 2 }).subscribe(
          res => this.pastEvents = res.body
        );
    }

    loadFutureEvents() {
        this.eventService.futureVoxxedEvents( { size: 12} ).subscribe(
      res => {
                this.futureEvents = res.body;
                this.loading = false;
            });
    }

    getEvent(item: number) {
        if (this.futureEvents != null) {
            return this.futureEvents[item];
        } else {
            return null;
        }
    }

    getVisibility(index: number) {
        if (this.futureEvents == null || index >= this.futureEvents.length) {
            return 'hidden';
        }
    }

    getPastEvent(item: number) {
        if (this.pastEvents != null) {
            return this.pastEvents[item];
        }
    }
}

<div class="section subpage-section">
    <div class="container w-container">
        <div class="align-left page-title-block">
            <h1 class="page-title">The story of us</h1>
            <h2 class="page-subtitle">We are a passionate team of developers who like to organise technical conferences.</h2>
        </div>
        <div class="text-row w-row">
            <div class="text-column-left w-col w-col-6 w-col-stack">
                <h2 class="column-title">Launched in 2014</h2>
                <p class="block-paragraph">Voxxed Days was founded by Stephan Janssen & Mark Hazell in 2014 in response to
                  demand for developer events in locations where strong, independent user group communities existed.
                  Based on the same principle of Devoxx, which was founded by Stephan way back in 2001,
                  Voxxed Days is a series of tech events organised by local community groups.
                  The mantra of "Content is King" brings together internationally renowned and local speakers to deliver the very best in up-to-date topics.</p>
            </div>
            <div class="recent-post-column-right w-col w-col-6 w-col-stack">
                <div class="large-column-title">UPCOMING</div>
                    <div class="blog-list-wrapper w-dyn-list">
                        <div class="blog-list w-dyn-items">
                            <div class="blog-list-item w-dyn-item">
                                <app-event-item [event]="getEvent(0)"></app-event-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="large-quote-block">
            <h2 class="large-quote-heading">&quot;The only thing that is constant is change&quot;</h2>
            <div class="quote-author">- Heraclitus</div>
        </div>
        <div class="text-row w-row">
            <div class="recent-post-column-left w-col w-col-6 w-col-stack">
                <div class="large-column-title">FROM DEVELOPERS, FOR DEVELOPERS</div>
                <div class="blog-list-wrapper w-dyn-list">
                    <div class="blog-list w-dyn-items">
                        <div class="blog-list-item w-dyn-item">
                            <app-event-item [event]="getEvent(1)"></app-event-item>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-column-right w-col w-col-6 w-col-stack">
                <h2 class="column-title">Local Communities</h2>
                <p class="block-paragraph">Like Devoxx, Voxxed Days is spearheaded and supported by local communities.
                  This means each event retains a unique regional flavour, whilst being part of the overall family of events.
                  A spirit of openness, community, killer content and super-low priced tickets underpin the overall philosophy.
                </p>
                <a href="#/" class="button outline-button w-button">FUTURE EVENTS</a></div>
        </div>
        <div class="text-row w-row">
            <div class="text-column-left w-col w-col-6 w-col-stack">
                <h2 class="column-title">Developers-centric</h2>
                <p class="block-paragraph">Topics covered at Voxxed Days fall under the same radar as Devoxx.com,
                  including: Java, Cloud, Data, AI, Frontend, Programming Languages, Architecture, Security, Methodology, Culture and Future Technologies.</p>
            </div>
            <div class="text-column-right w-col w-col-6 w-col-stack">
                <div class="simple-categories-list-wrapper w-dyn-list">
                    <div class="simple-categories-list w-dyn-items w-row">
                        <div class="w-col w-col-6 w-dyn-item"><a class="category-link"></a></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="on-subpage team-wrapper">
            <div class="team-column-wrapper w-clearfix">
                <div class="team-column-block">
                    <div class="authors-list-wrapper w-dyn-list">
                        <div class="authors-list w-dyn-items w-row">
                            <div class="author-list-item w-col w-col-6 w-dyn-item">
                                <app-speaker-item [speaker]="speakers[0]" [isLarge]=true></app-speaker-item>
                            </div>
                            <div class="author-list-item w-col w-col-6 w-dyn-item">
                              <app-speaker-item [speaker]="speakers[1]" [isLarge]=true></app-speaker-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right team-column-block">
                    <div class="team-page-info-block">
                        <div class="info-block-title">Hello!</div>
                        <div class="info-block-title subtitle">Meet our Champions</div><a href="https://beta.voxxed.com/#/speakers" class="button outline-button w-button">Explore</a></div>
                </div>
            </div>
        </div>
    </div>
</div>

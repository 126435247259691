import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { createRequestOption } from '../../shared/util/request-util';
import {ISpeaker} from "../speaker";

type EntityArrayResponseType = HttpResponse<ISpeaker[]>;

@Injectable()
export class PlayerService {

  constructor(private http: HttpClient) {
  }

  loadSpeakers(resourceUrl: string, req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ISpeaker[]>(resourceUrl + 'public/speakers', { params: options, observe: 'response' });
  }
}

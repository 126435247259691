import {Component, Input} from '@angular/core';
import {Event, EventCategory} from '../../entities/event';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-event-item',
    templateUrl: 'eventItem.component.html'
})
export class EventItemComponent {

    @Input() event: Event;

    @Input() isMedium: boolean = true;
    @Input() isSmall: boolean = false;
    @Input() isArchived: boolean = false;

    @Input() clearFix: false;

    isChrome: boolean = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    isOpera: boolean = /Opera/.test(navigator.userAgent);

    constructor() {}

    getEventDate() {
        if (this.event != null &&
            this.event.fromDate != null &&
            this.event.toDate != null) {

            const DATE_FORMAT = 'D MMM YYYY';
            const DAY_FORMAT = 'D';
            const fromDate = moment(this.event.fromDate).tz('Europe/Brussels');
            const toDate = moment(this.event.toDate).tz('Europe/Brussels');

            if (fromDate.days() === toDate.days()) {
                return fromDate.format(DATE_FORMAT).toString();
            } else {
                return fromDate.format(DAY_FORMAT).toString() + ' - ' + toDate.format(DATE_FORMAT).toString();
            }
        }
    }

    getEventImageURL(isSmall: boolean) {
      if (this.event.imageURL.startsWith("https://devoxxian-image-thumbnails")) {
        // The NEW image thumbnail logic using AWS lamba
        return this.event.imageURL
      } else {
        let extension = '.jpg';

        if (this.event != null &&
          this.event.imageURL != null &&
          this.event.imageURL.length > 0) {

          let strings;

          if (this.event.imageURL.endsWith('.png')) {
            strings = this.event.imageURL.split('.png');
          } else if (this.event.imageURL.endsWith('.jpg')) {
            strings = this.event.imageURL.split('.jpg');
          }

          if (isSmall) {
            return strings[0] + '_medium' + extension;
          } else {
            return strings[0] + '_large' + extension;
          }
        }
      }
    }

    getCategoryColor() {
      if (this.event.eventCategory !== null) {
        const eventCat = this.event.eventCategory.toString().toLocaleLowerCase();
        if (eventCat === 'devoxx') {
          return '#dd9933';
        } else if (eventCat === 'voxxed') {
          return '#27b1db';
        } else {
          return '#ff0000';
        }
      }
    }

    getEventUrl(): string {
      if (this.event.eventCategory === EventCategory.ONLINE) {
        return '#/player/' + this.event.id;
      } else {
        return this.event.website;
      }
    }

    getTarget(): string {
      if (this.event.eventCategory === EventCategory.ONLINE) {
        return '_self';
      } else {
        return '_blank';
      }
    }
}

<div class="section subpage-section">
    <div class="container w-container">
        <div class="page-title-block">
            <h1 class="page-title">Devoxxians</h1>
            <h2 class="page-subtitle">Meet the people who entertain you with their knowledge</h2>
        </div>
        <!--<div class="w-form">-->
            <form name="searchForm" novalidate (ngSubmit)="findSpeakers()">
                <input required [(ngModel)]="searchText" name="searchText" type="text" class="text-field w-input" maxlength="50" placeholder="Search for a speaker">
            </form>
        <!--</div>-->
        <div class="team-wrapper">
            <div class="team-column-wrapper w-clearfix">
                <div class="right team-column-block w-hidden-small w-hidden-tiny">
                    <div class="team-page-info-block">
                        <div class="info-block-title">Presentations</div>
                        <div class="info-block-title subtitle">Discover the presentations from our speakers</div><a href="https://beta.voxxed.com" class="button outline-button w-button">LEARN</a></div>
                </div>
                <div class="team-column-block">
                    <div class="authors-list-wrapper w-dyn-list">
                        <div class="authors-list w-dyn-items w-row">
                            <div class="author-list-item w-col w-col-6 w-dyn-item">
                                <app-speaker-item [speaker]="getSpeaker(0)"></app-speaker-item>
                            </div>
                            <div class="author-list-item w-col w-col-6 w-dyn-item">
                                <app-speaker-item [speaker]="getSpeaker(1)"></app-speaker-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="authors-list-wrapper w-dyn-list">
                <div class="authors-list w-dyn-items w-row">

                    <div class="author-list-item w-col w-col-4 w-dyn-item">
                        <app-speaker-item [speaker]="getSpeaker(2)"></app-speaker-item>
                    </div>

                    <div class="author-list-item w-col w-col-4 w-dyn-item">
                        <app-speaker-item [speaker]="getSpeaker(3)"></app-speaker-item>
                    </div>

                    <div class="author-list-item w-col w-col-4 w-dyn-item">
                        <app-speaker-item [speaker]="getSpeaker(4)"></app-speaker-item>
                    </div>

                    <div class="author-list-item w-col w-col-4 w-dyn-item">
                        <app-speaker-item [speaker]="getSpeaker(5)"></app-speaker-item>
                    </div>

                    <div class="author-list-item w-col w-col-4 w-dyn-item">
                        <app-speaker-item [speaker]="getSpeaker(6)"></app-speaker-item>
                    </div>

                    <div class="author-list-item w-col w-col-4 w-dyn-item">
                        <app-speaker-item [speaker]="getSpeaker(7)"></app-speaker-item>
                    </div>

                    <div class="author-list-item w-col w-col-4 w-dyn-item">
                        <app-speaker-item [speaker]="getSpeaker(8)"></app-speaker-item>
                    </div>

                    <div class="author-list-item w-col w-col-4 w-dyn-item">
                        <app-speaker-item [speaker]="getSpeaker(9)"></app-speaker-item>
                    </div>

                    <div class="author-list-item w-col w-col-4 w-dyn-item">
                        <app-speaker-item [speaker]="getSpeaker(10)"></app-speaker-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="post-header-wrapper">
    <div data-ix="fade-in-post-header" class="post-image-header" [style.background-image]="'url(' + event?.imageURL+ ')'">
        <div class="blog-post-header-overlay">
            <div id="start-reading" class="container w-container">
                <div class="post-header-title-wrapper">
                    <div class="blog-post-date-title">{{getFromDate()}}</div>
                    <h1 class="blog-post-main-title">{{event?.name}}</h1>
                </div>
                <div class="w-row">
                    <a *ngIf="isNotPastEvent()" href="https://api.voxxed.com/api/voxxeddays/events/ical/event/devoxx">
                        <div class="button w-button featured">
                            <img src="https://s3-eu-west-1.amazonaws.com/voxxeddays/webapp/icons/addDates.png">
                            <span>Events</span>
                        </div>
                    </a>
                    <a *ngIf="isNotPastEvent()" href="https://api.voxxed.com/api/voxxeddays/events/ical/cfp/devoxx">
                        <div class="button w-button featured">
                            <img src="https://s3-eu-west-1.amazonaws.com/voxxeddays/webapp/icons/addDates.png">
                            <span>CFP Dates</span>
                        </div>
                    </a>
                    <a href="{{event?.website}}">
                        <div class="button w-button featured">
                            <img src="https://s3-eu-west-1.amazonaws.com/voxxeddays/webapp/icons/www-icon.png">
                            <span>WWW</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="continue-reading" class="blog-post section">
    <div data-ix="new-interaction" class="container w-container">
        <div data-ix="hide-blog-content-block" id="read-post" class="blog-post-content-wrapper">
            <div class="blog-content-block">
                <div class="rich-text-block w-richtext"></div>
                <div data-ix="show-read-more-block" class="return-block"></div>
            </div>
        </div>
    </div>
    <div data-ix="hide-read-more-block" class="read-more-fixed-block"><a href="#" data-ix="close-read-more" class="close-read-more-button w-inline-block">
        <img src="https://s3-eu-west-1.amazonaws.com/devoxxians/images/app/Icon-close-thin.png" class="close-icon"></a>
        <div class="collection-list-wrapper-2 w-dyn-list">
            <div class="collection-list w-dyn-items">
                <div class="collection-item w-dyn-item">
                    <div data-ix="blog-show-author" class="blog-post-wrapper read-more">
                        <a [routerLink]="['../' + nextEvent?.id]" data-ix="blog-image-zoom" class="post-overlay-block read-more w-inline-block">
                            <div class="also-read-title">NEXT UP:</div>
                            <div class="blog-post-title read-more">{{nextEvent?.name}}</div>
                            <div class="read-more-button small">
                                <img src="https://s3-eu-west-1.amazonaws.com/devoxxians/images/app/Icon-arrow-right-white_1.png" alt="More details" class="read-more-arrow">
                            </div>
                        </a>
                        <div class="blog-image-block read-more" [style.background-image]="'url(' + getNextEventImageURL(nextEvent) + ')'"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section">
    <div class="container w-container">
        <div class="title-wrapper">
            <div data-delay="0" class="filter-dropdown w-dropdown">
                <div class="filter-dropdown-toggle w-dropdown-toggle">
                    <div class="w-icon-dropdown-toggle"></div>
                    <div class="dropdown-title">Filter by Category</div>
                </div>
                <nav class="dropdown-list-block w-dropdown-list">
                    <div class="collection-list-wrapper w-dyn-list">
                        <div class="dropdown-list w-dyn-items">
                            <div class="dropdown-list-item w-dyn-item"><a [routerLink]="['../events/devoxx']"
                                                                          class="dropdown-link w-dropdown-link">Devoxx
                                Events</a></div>
                            <div class="dropdown-list-item w-dyn-item"><a [routerLink]="['../events/voxxed']"
                                                                          class="dropdown-link w-dropdown-link">VoxxxedDays</a>
                            </div>
                        </div>
                    </div>
                </nav>
                <img *ngIf="isLoading()" src="https://s3-eu-west-1.amazonaws.com/devoxxians/images/app/Loading.gif" height="40px" style="margin-top:5px;margin-left:10px">
            </div>
        </div>
        <div class="blog-column-wrapper w-clearfix">
            <div class="blog-column push-down">
                <div class="blog-list-wrapper w-dyn-list">
                    <div class="blog-list w-dyn-items">
                        <div data-ix="fade-in-on-scroll" class="blog-list-item w-dyn-item">
                            <app-event-item [event]="getEvent(0)" [style.visibility]="getVisibility(0)"></app-event-item>
                        </div>
                        <div data-ix="fade-in-on-scroll" class="blog-list-item w-dyn-item">
                            <app-event-item [event]="getEvent(1)" [style.visibility]="getVisibility(2)"></app-event-item>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-column right">
                <div class="blog-list-wrapper w-dyn-list">
                    <div class="blog-list w-dyn-items">
                        <div data-ix="fade-in-on-scroll-2" class="blog-list-item w-dyn-item">
                            <app-event-item [event]="getEvent(2)" [style.visibility]="getVisibility(1)"></app-event-item>
                        </div>
                    </div>
                </div>
                <div class="blog-list-wrapper w-dyn-list">
                    <div class="blog-list w-dyn-items w-row">
                        <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                            <app-event-item [event]="getEvent(3)" [isSmall]="true" [style.visibility]="getVisibility(3)"></app-event-item>
                        </div>
                        <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                            <app-event-item [event]="getEvent(4)" [isSmall]="true" [style.visibility]="getVisibility(4)"></app-event-item>
                        </div>
                        <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                            <app-event-item [event]="getEvent(5)" [isSmall]="true" [style.visibility]="getVisibility(5)"></app-event-item>
                        </div>
                        <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                            <app-event-item [event]="getEvent(6)" [isSmall]="true" [style.visibility]="getVisibility(6)"></app-event-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <span>
            <div class="blog-column-wrapper w-clearfix">
                <div class="blog-column">
                    <div class="blog-list-wrapper w-dyn-list">
                        <div class="blog-list w-dyn-items w-row">
                            <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                                <app-event-item [event]="getEvent(8)" [isSmall]="true"></app-event-item>
                            </div>
                            <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                                <app-event-item [event]="getEvent(9)" [isSmall]="true"></app-event-item>
                            </div>
                            <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                                <app-event-item [event]="getEvent(10)" [isSmall]="true"></app-event-item>
                            </div>
                            <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                                <app-event-item [event]="getEvent(11)" [isSmall]="true"></app-event-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="blog-column push-up right">
                    <div class="blog-list-wrapper w-dyn-list">
                        <div class="blog-list w-dyn-items">
                            <div data-ix="fade-in-on-scroll-2" class="blog-list-item w-dyn-item" style="padding-top: 50px;">
                                <app-event-item [event]="getEvent(7)"></app-event-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>

      <div class="row">
          <a href="https://www.devoxxians.com/api/public/calendar" class="nav-add-cal">
            <img src="https://s3-eu-west-1.amazonaws.com/voxxeddays/webapp/icons/addDatesGray.png">
            <span class="calText">Add all events to your calendar</span>
          </a>
      </div>

      <div data-ix="fade-in-on-scroll-3" class="read-more-block">
            <div class="read-more-overlay"><a [routerLink]="['archive']" class="button outline-button w-button">Past Events</a></div>
            <div class="blog-list-wrapper w-dyn-list">
                <div class="blog-list w-dyn-items w-row">
                    <div data-ix="fade-in-on-scroll" class="blog-list-item w-col w-col-6 w-dyn-item">
                        <app-event-item [event]="getPastEvent(1)" isArchived="true"></app-event-item>
                    </div>
                    <div data-ix="fade-in-on-scroll" class="blog-list-item w-col w-col-6 w-dyn-item">
                        <app-event-item [event]="getPastEvent(0)" isArchived="true"></app-event-item>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

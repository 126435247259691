<div class="section subpage-section" xmlns="http://www.w3.org/1999/html">
    <div class="container w-container">
        <div class="page-title-block">
            <h1 class="page-title">Discover our events</h1>
            <h2 class="page-subtitle">Select your favourite event series</h2>
        </div>
        <div class="categories-list-wrapper w-dyn-list">
            <div class="categories-list w-dyn-items w-row">
                <div class="category-item w-col w-col-3 w-dyn-item"><a [routerLink]="['../events', 'devoxx']"
                                                                       data-ix="blog-image-zoom"
                                                                       class="category-link-block w-inline-block">
                    <div data-ix="blog-image-zoom" class="category-overlay" style="transition: background-color 0.2s ease 0s;">
                        <div class="category-title-wrapper w-clearfix"><h2 class="category-block-title">Devoxx
                            Events</h2>
                            <p class="category-description">From Developers, For Developers. Devoxx are annual events
                                taking place in Belgium, France, UK, Poland, Morocco and Unites States.</p>
                            <div class="read-more-button small">
                                <img src="https://daks2k3a4ib2z.cloudfront.net/59d7736c8976e40001599a4d/59d7736c8976e40001599a69_Icon-arrow-right-white_1.png"
                                     class="read-more-arrow" alt="More details">
                            </div>
                        </div>
                    </div>
                    <div
                        style="background-image: url('https://s3-eu-west-1.amazonaws.com/voxxeddays/webapp/homepage/presentations.jpg'); transform-style: preserve-3d; transition: transform 300ms; transform: scaleX(1.1) scaleY(1.1) scaleZ(1);"
                        class="blog-image-block category"></div>
                </a></div>
                <div class="category-item w-col w-col-3 w-dyn-item"><a [routerLink]="['../events', 'voxxed']"
                                                                       data-ix="blog-image-zoom"
                                                                       class="category-link-block w-inline-block">
                    <div data-ix="blog-image-zoom" class="category-overlay" style="transition: background-color 0.2s ease 0s;">
                        <div class="category-title-wrapper w-clearfix"><h2 class="category-block-title">VoxxxedDays</h2>
                            <p class="category-description">Voxxed and Devoxx are part of the same family.
                                Voxxed Days are community-organized events that share the ethos &amp; DNA of Devoxx.
                                It is a series of events, organized locally, where a gap exists in the developer's
                                community conference scene.</p>
                            <div class="read-more-button small"><img
                                src="https://daks2k3a4ib2z.cloudfront.net/59d7736c8976e40001599a4d/59d7736c8976e40001599a69_Icon-arrow-right-white_1.png"
                                class="read-more-arrow" alt="More details"></div>
                        </div>
                    </div>
                    <div
                        style="background-image: url('https://s3-eu-west-1.amazonaws.com/devoxxians/images/voxxed/singapore/event.jpg'); transform-style: preserve-3d; transition: transform 300ms; transform: scaleX(1.1) scaleY(1.1) scaleZ(1);"
                        class="blog-image-block category"></div>
                </a></div>
                <div class="category-item w-col w-col-3 w-dyn-item"><a href="https://www.rokkit.live/"
                                                                       data-ix="blog-image-zoom"
                                                                       class="category-link-block w-inline-block">
                  <div data-ix="blog-image-zoom" class="category-overlay" style="transition: background-color 0.2s ease 0s;">
                    <div class="category-title-wrapper w-clearfix"><h2 class="category-block-title">Rokkit</h2>
                      <p class="category-description">We have developed a brand new event platform named Rokkit.
                        This platform allows us to organise hybrid or online events with many cool interactive features, like virtual booths, discussions, photo booths etc.</p>
                    </div>
                  </div>
                  <div
                    style="background-image: url('https://s3-eu-west-1.amazonaws.com/voxxeddays/webapp/images/30198845162_2af45a89a5_z.jpg'); transform-style: preserve-3d; transition: transform 300ms; transform: scaleX(1.1) scaleY(1.1) scaleZ(1);"
                    class="blog-image-block category"></div>
                </a></div>
                <div class="category-item w-col w-col-3 w-dyn-item"><a href="http://www.devoxx4kids.org/"
                                                                       data-ix="blog-image-zoom"
                                                                       class="category-link-block w-inline-block">
                    <div data-ix="blog-image-zoom" class="category-overlay" style="transition: background-color 0.2s ease 0s;">
                        <div class="category-title-wrapper w-clearfix"><h2 class="category-block-title">Devoxx4Kids</h2>
                            <p class="category-description">Inspire children to programming, robotics and engineering through fun hands-on workshops.</p>
                            <div class="read-more-button small"><img
                                src="https://daks2k3a4ib2z.cloudfront.net/59d7736c8976e40001599a4d/59d7736c8976e40001599a69_Icon-arrow-right-white_1.png"
                                class="read-more-arrow" alt="More details"></div>
                        </div>
                    </div>
                    <div
                        style="background-image: url('https://s3-eu-west-1.amazonaws.com/voxxeddays/webapp/images/devoxx4kids.jpg'); transform-style: preserve-3d; transition: transform 300ms; transform: scaleX(1.1) scaleY(1.1) scaleZ(1);"
                        class="blog-image-block category"></div>
                </a></div>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';

import { EventService, Event } from '../event';
import { WindowRef } from '../../home/WindowRef';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {

    events: Event[];

    name: string;
    email: string;

    constructor(
        private eventService: EventService,
        private winRef: WindowRef
    ) {
    }

    ngOnInit() {
        this.winRef.nativeWindow.webFlow.destroy();
        this.winRef.nativeWindow.webFlow.ready();

        this.loadFutureEvents();
    }

    loadFutureEvents() {
        this.eventService.futureVoxxedEvents().subscribe(
          res => this.events = res.body,
          res => console.log(res.json));
    }

    getEvent(item: number) {
        if (this.events != null) {
            return this.events[item];
        } else {
            return null;
        }
    }
}

<div data-ix="blog-show-author"
     *ngIf="event"
     class="blog-post-wrapper"
     [ngClass]="{'small':isSmall}"
     [ngClass]="{'medium':isMedium}"
     [ngClass]="{'archive':isArchived}">

        <a href="#/events/{{event?.eventCategory}}"
           [style.background-color]="getCategoryColor()"
           class="blog-category-title-link">
            {{event?.eventCategory}}
        </a>

        <div class="event-date-block">
            <div class="event-date">{{getEventDate()}}</div>
        </div>
        <a data-ix="blog-image-zoom" class="post-overlay-block w-inline-block" href="{{getEventUrl()}}" target="{{getTarget()}}">
            <div class="blog-post-title" [ngClass]="{'small-title':isSmall}">{{event?.name}}</div>
            <div class="read-more-button" [ngClass]="{'small':isSmall}">
                <img src="https://s3-eu-west-1.amazonaws.com/devoxxians/images/app/Icon-arrow-right-white_1.png" class="read-more-arrow" alt="More details">
            </div>
        </a>
        <div class="blog-image-block"
             [style.background-image]="'url(' + getEventImageURL(isSmall) + ')'"
             [ngClass]="{'small':isSmall}">
        </div>
</div>

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { VoxxedDaysSpeakerModule } from './speaker/speaker.module';
import { VoxxedDaysManifestoModule } from './manifesto/manifesto.module';
import { VoxxedDaysCategoryModule } from './category/category.module';
import { VoxxedDaysContactModule } from './contact/contact.module';
import { VoxxedDaysArchiveModule } from './archive/archive.module';
import { VoxxedDaysAboutModule } from './about/about.module';
import { VoxxedDaysEventModule } from './event/event.module';
import { VoxxedDaysPlayerModule } from './player/player.module';

@NgModule({
    imports: [
      VoxxedDaysSpeakerModule,
      VoxxedDaysEventModule,
      VoxxedDaysAboutModule,
      VoxxedDaysCategoryModule,
      VoxxedDaysArchiveModule,
      VoxxedDaysContactModule,
      VoxxedDaysManifestoModule,
      VoxxedDaysPlayerModule
    ],
    declarations: [],
    entryComponents: [],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VoxxedDaysEntityModule {}

<div class="section subpage-section">
    <div class="container w-container">
        <jhi-alert></jhi-alert>
        <div class="page-title-block">
            <h1 class="page-title">Get in touch with us</h1>
            <h2 class="page-subtitle">Interested in joining the Devoxx family?</h2>
        </div>
        <div class="contact-wrapper">
            <div class="contact-block">
                <a href="mailto:info@voxxed.com" class="contact-link">
                    <div class="contact-icon-block">
                            <img src="https://s3-eu-west-1.amazonaws.com/devoxxians/images/app/001-mail-black-envelope-symbol.svg" alt="Email us" class="contact-icon">
                    </div>
                    <div class="contact-title">Email Us</div>
                </a>
            </div>
            <div class="contact-block">
                <a href="https://facebook.com/voxxed" class="contact-link">
                    <div class="contact-icon-block">
                        <img src="https://s3-eu-west-1.amazonaws.com/devoxxians/images/app/004-facebook-logo.svg" alt="Like us" class="contact-icon">
                    </div>
                    <div class="contact-title">Like Us</div>
                </a>
            </div>
            <div class="contact-block">
                <a href="https://twitter.com/voxxed" class="contact-link">
                    <div class="contact-icon-block"><img src="https://s3-eu-west-1.amazonaws.com/devoxxians/images/app/003-twitter.svg" alt="Follow us" class="contact-icon">
                    </div>
                    <div class="contact-title">Follow Us</div>
                </a>
            </div>
        </div>
        <div class="text-row w-row">
            <div class="recent-post-column-right w-col w-col-6 w-col-stack">
                <div class="large-column-title">NEXT EVENT</div>
                <div class="blog-list-wrapper w-dyn-list">
                    <div class="blog-list w-dyn-items">
                        <div class="blog-list-item w-dyn-item">
                            <app-event-item [event]="getEvent(0)"></app-event-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';

import { EventService, Event } from '../event';
import { WindowRef } from '../../home/WindowRef';
import {ActivatedRoute} from "@angular/router";
import * as moment from "moment-timezone";
import {PlayerService} from "./player.service";
import {Speaker} from "../speaker";

@Component({
    selector: 'app-player',
    templateUrl: './player.component.html'
})
export class PlayerComponent implements OnInit {

  event: Event;

  speakers: Speaker[];

  fromDate: any;
  toDate: any;

  constructor(
        private eventService: EventService,
        private route: ActivatedRoute,
        private playerService: PlayerService,
        private winRef: WindowRef
    ) {
  }

  ngOnInit() {
    this.winRef.nativeWindow.webFlow.destroy();
    this.winRef.nativeWindow.webFlow.ready();

    this.route.params.subscribe((params) => {
      const ID = 'id';
      this.load(params[ID]);
    });
  }

  load(id): void {
    this.eventService.find(id).subscribe(res => {
      this.event = res.body;

      this.loadSpeakers();
      // loadSchedule();

    }, res => this.onError(res.json));
  }

  private onError(error) {
    console.log(error);
  }

  private loadSpeakers(): void {
    console.log(this.event.apiURL);

    if (!this.event.apiURL.endsWith('/')) {
      this.event.apiURL = this.event.apiURL + '/';
    }
    console.log(this.event.apiURL);

    this.playerService.loadSpeakers(this.event.apiURL).subscribe(value => {
      this.speakers = value.body;
      console.log(this.speakers);
    })
  }

  private getEventDate() {
    if (this.event != null) {

      this.fromDate = moment(this.event.fromDate).tz(this.event.timezone);
      this.toDate = moment(this.event.toDate).tz(this.event.timezone);

      if (this.fromDate.dayOfYear() !== this.toDate.dayOfYear()) {
        if (this.fromDate.month() === this.toDate.month()) {
          return (
            'From ' +
            this.fromDate.format('Do') +
            ' to ' +
            this.showEndDay() +
            ' of ' +
            this.showMonth() +
            this.showYear()
          );
        } else {
          return (
            'From ' +
            this.showStartDay() +
            ' of ' +
            this.showStartMonth() +
            ' to ' +
            this.showEndDay() +
            ' of' +
            this.showMonth() +
            this.showYear()
          );
        }
      } else {
        return this.showStartDay() + ' of ' + this.showStartMonth() + this.showYear() + ' from ' + this.getFromTime() + ' until ' + this.getToTime();
      }
    }
  }

  private showYear() {
    if (!this.event.name.includes('20')) {
      return ' ' + this.toDate.format('YYYY');
    } else {
      return '';
    }
  }

  private showEndDay() {
    return this.toDate.format('Do');
  }

  private showMonth() {
    return this.toDate.format('MMMM');
  }

  private showStartDay() {
    return this.fromDate.format('Do');
  }

  private showStartMonth() {
    return this.fromDate.format('MMMM');
  }

  private getFromTime() {
    return this.fromDate.format('hh:mm');
  }

  private getToTime() {
    return this.toDate.format('hh:mm');
  }
}

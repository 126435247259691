import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
    PlayerComponent,
    playerRoute,
} from './';
import { VoxxedDaysSharedModule } from '../../shared/shared.module';
import {PlayerService} from "./player.service";

const ENTITY_STATES = [
    ...playerRoute,
];

@NgModule({
    imports: [
      VoxxedDaysSharedModule,
      RouterModule.forRoot(ENTITY_STATES, { useHash: true })
    ],
    declarations: [
      PlayerComponent,
    ],
    entryComponents: [
      PlayerComponent,
    ],
    providers: [
      PlayerService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VoxxedDaysPlayerModule {}

import './vendor.ts';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { VoxxedDaysAppRoutingModule } from './app-routing.module';
import { PaginationConfig } from './blocks/config/uib-pagination.config';

import {
    NavbarComponent,
    FooterComponent,
    ActiveMenuDirective,
    ErrorComponent
} from './layouts';

import { JhiMainComponent } from './layouts';
import { VoxxedDaysSharedModule } from './shared/shared.module';
import { VoxxedDaysHomeModule } from './home/home.module';
import { VoxxedDaysEntityModule } from './entities/entity.module';

@NgModule({
    imports: [
      BrowserModule,
      VoxxedDaysAppRoutingModule,
      VoxxedDaysSharedModule,
      VoxxedDaysHomeModule,
      VoxxedDaysEntityModule,
    ],
    declarations: [
      JhiMainComponent,
      NavbarComponent,
      ErrorComponent,
      ActiveMenuDirective,
      FooterComponent,
    ],
    providers: [
      PaginationConfig,
    ],
    bootstrap: [ JhiMainComponent ]
})
export class VoxxedDaysAppModule {}

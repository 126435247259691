import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EventService, Event } from '../event';
import { WindowRef } from '../../home/WindowRef';
import { NAME } from '../../shared/constants/param.constants';

@Component({
    selector: 'app-category-detail',
    templateUrl: './category-detail.component.html'
})
export class CategoryDetailComponent implements OnInit {

    events: Event[];
    categoryName: string;
    loading: boolean;

    constructor(
        private eventService: EventService,
        private route: ActivatedRoute,
        private winRef: WindowRef
    ) {
    }

    ngOnInit() {
        this.winRef.nativeWindow.webFlow.destroy();
        this.winRef.nativeWindow.webFlow.ready();

        this.route.params.subscribe((params) => {
            this.categoryName = params[NAME];
            this.loadEventsByCategory();
        });
    }

    isLoading() {
        return this.loading;
    }

    loadEventsByCategory() {
        this.loading = true;
        if (this.categoryName.toLocaleLowerCase() === 'devoxx') {
            this.eventService.futureDevoxxEvents().subscribe(
              res => this.onSuccess(res.body),
              res => this.onError(res.json)
            );
        } else if (this.categoryName.toLocaleLowerCase() === 'voxxed') {
            this.eventService.futureVoxxedEvents().subscribe(
              res => this.onSuccess(res.body),
              res => this.onError(res.json)
            );
        } else {
          this.eventService.futureOnlineEvents().subscribe(
            res => this.onSuccess(res.body),
            res => this.onError(res.json)
          );
        }
    }

    getEvent(item: number) {
        if (this.events != null) {
            return this.events[item];
        } else {
            return null;
        }
    }

    private onSuccess(data: Event[]) {
      this.events = data;
      this.loading = false;
    }

    private onError(error) {
      console.log(error);
    }
}

<div class="section">
    <div class="container w-container">
        <div class="page-title-block">
            <div class="page-title-wrapper">
                <h1 class="dynamic-page-title page-title">Past Events</h1>
            </div>
        </div>
        <div class="blog-column-wrapper w-clearfix">
            <div class="blog-column push-down">
                <div class="blog-list-wrapper w-dyn-list">
                    <div class="blog-list w-dyn-items">
                        <div data-ix="fade-in-on-scroll" class="blog-list-item w-dyn-item">
                            <app-event-item [event]="getEvent(0)"></app-event-item>
                        </div>
                        <div data-ix="fade-in-on-scroll" class="blog-list-item w-dyn-item">
                            <app-event-item [event]="getEvent(2)"></app-event-item>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-column right">
                <div class="blog-list-wrapper w-dyn-list">
                    <div class="blog-list w-dyn-items">
                        <div data-ix="fade-in-on-scroll-2" class="blog-list-item w-dyn-item">
                            <app-event-item [event]="getEvent(1)"></app-event-item>
                        </div>
                    </div>
                </div>
                <div class="blog-list-wrapper w-dyn-list">
                    <div class="blog-list w-dyn-items w-row">
                        <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                            <app-event-item [event]="getEvent(3)" [isSmall]="true"></app-event-item>
                        </div>
                        <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                            <app-event-item [event]="getEvent(4)" [isSmall]="true"></app-event-item>
                        </div>
                        <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                            <app-event-item [event]="getEvent(5)" [isSmall]="true"></app-event-item>
                        </div>
                        <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                            <app-event-item [event]="getEvent(6)" [isSmall]="true"></app-event-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span *ngIf="getEvent(7)">
            <div class="blog-column-wrapper w-clearfix">
                <div class="blog-column">
                    <div class="blog-list-wrapper w-dyn-list">
                        <div class="blog-list w-dyn-items w-row">
                            <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                                <app-event-item [event]="getEvent(7)" [isSmall]="true"></app-event-item>
                            </div>
                            <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                                <app-event-item [event]="getEvent(8)" [isSmall]="true"></app-event-item>
                            </div>
                            <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-6 w-dyn-item">
                                <app-event-item [event]="getEvent(9)" [isSmall]="true"></app-event-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="blog-column push-up right">
                    <div class="blog-list-wrapper w-dyn-list">
                        <div class="blog-list w-dyn-items">
                            <div data-ix="fade-in-on-scroll-2" class="blog-list-item w-dyn-item" style="padding-top: 50px;">
                                <app-event-item [event]="getEvent(10)"></app-event-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>
</div>

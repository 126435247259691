import { Moment } from 'moment';

export const enum EventCategory {
  VOXXED = 'VOXXED',
  DEVOXX = 'DEVOXX',
  ONLINE = 'ONLINE',
  MEETUP = 'MEETUP'
}

export interface IEvent {
  id?: number;
  name?: string;
  website?: string;
  description?: any;
  imageURL?: string;
  scheduleURL?: string;
  eventImagesURL?: string;
  fromDate?: any;
  toDate?: any;
  cfpFromDate?: any;
  cfpToDate?: any;
  eventCategory?: EventCategory;
  cfpKey?: string;
  archived?: boolean;
  importDate?: any;
  locationId?: number;
  timezone?: string;
  apiURL?: string
}

export class Event implements IEvent {
  constructor(
    public id?: number,      // 1
    public name?: string,    // 2
    public website?: string,
    public description?: any,   // 6
    public imageURL?: string,  // 5
    public scheduleURL?: string,
    public eventImagesURL?: string,
    public fromDate?: any,           // 4
    public toDate?: any,
    public cfpFromDate?: any,
    public cfpToDate?: any,
    public eventCategory?: EventCategory,  // 3
    public cfpKey?: string,
    public archived?: boolean,
    public importDate?: any,
    public locationId?: number,
    public timezone?: string,
    public apiURL?: string
  ) {
    this.archived = false;
  }
}

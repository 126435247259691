import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
    SpeakerService,
    SpeakerComponent,
    SpeakerDetailComponent,
    speakerRoute,
} from './';
import {VoxxedDaysSharedModule} from '../../shared/shared.module';

const ENTITY_STATES = [
    ...speakerRoute,
];

@NgModule({
    imports: [
      VoxxedDaysSharedModule,
      RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
      SpeakerComponent,
      SpeakerDetailComponent,
    ],
    entryComponents: [
      SpeakerComponent,
    ],
    providers: [
      SpeakerService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VoxxedDaysSpeakerModule {}

<div data-ix="hide-blur-post-header" class="blur-header-wrapper">
    <div class="blur-gradient-overlay"></div>
    <div class="blur-header">
        <div class="post-image-header">
            <div class="blog-post-header-overlay">
                <div class="container w-container">
                    <div class="post-header-title-wrapper">
                        <div class="blog-post-date"></div>
                        <h1 class="blog-post-main-title"></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="author-header post-header-wrapper">
    <div class="blog-post-header-overlay team-member-overlay">
        <div id="start-reading" class="container w-container">
            <div class="team-header-block w-clearfix">
                <div class="team-header-image" [style.background-image]="'url(' +  getSpeakerAvatarURL() + ')'">
                    <div class="author-social-wrapper w-clearfix" [style.visibility]="hasTwitterHandle()">
                        <a href="https://twitter.com/{{speaker?.twitterHandle}}" target="_blank" class="author-social-link-block w-inline-block">
                            <img src="https://s3-eu-west-1.amazonaws.com/devoxxians/images/app/003-twitter.svg"
                                 class="small-social-icon">
                        </a>
                    </div>
                </div>
                <div class="team-member-block">
                    <div class="team-member-title">{{speaker?.firstName}} {{speaker?.lastName}}</div>
                    <div class="team-subtitle">{{speaker?.company}}</div>
                    <div class="w-richtext">{{speaker?.bio}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="author-header post-image-header"
         [style.background-image]="'url(' + speaker?.avatarURL + ')'"></div>
</div>
<div class="section subpage">
    <div class="container w-container">
        <div class="author-posts-wrapper blog-column-wrapper w-clearfix">
            <div class="dynamic-title-wrapper">
                <div class="dynamic-title">Presentations by</div>
                <div class="dynamic dynamic-title">{{speaker?.firstName}} {{speaker?.lastName}}</div>
                <div class="w-dyn-list">
                    <div class="collection-list-2 w-dyn-items">
                        <div class="w-dyn-item" *ngFor="let presentation of presentations">
                            <div class="w-row">
                                <div class="w-col w-col-9">
                                    <a href="https://beta.voxxed.com/#/presentation/{{presentation.id}}" style="color:#2d94c5;">
                                        <div>{{presentation.title}}</div>
                                    </a>
                                </div>
                                <div class="w-col w-col-3">
                                    {{presentation.eventName}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-column push-down"></div>
            <div class="blog-column right"></div>
        </div>
    </div>
</div>

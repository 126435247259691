import { Component, OnInit } from '@angular/core';

import { EventService, Event } from '../event';
import { WindowRef } from '../../home/WindowRef';
import { SpeakerService} from '../speaker';

@Component({
    selector: 'jhi-manifesto',
    templateUrl: './manifesto.component.html'
})
export class ManifestoComponent implements OnInit {

    events: Event[];
    speakers: Event[];

    constructor(
        private eventService: EventService,
        private speakerService: SpeakerService,
        private winRef: WindowRef
    ) {
    }

    ngOnInit() {
        this.loadFutureEvents();

        this.winRef.nativeWindow.webFlow.destroy();
        this.winRef.nativeWindow.webFlow.ready();
    }

    loadFutureEvents() {
        this.eventService.futureVoxxedEvents().subscribe(
          res => this.events = res.body,
        res => console.log(res.json)
        );

        this.speakerService.query({size: 3}).subscribe(
            res => this.speakers = res.body,
            res => this.onError(res.json)
        );
    }

    getEvent(item: number) {
        if (this.events != null) {
            return this.events[item];
        } else {
            return null;
        }
    }

    private onError(error) {
        console.log(error);
    }
}

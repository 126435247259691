import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
    ContactComponent,
    contactRoute,
} from './';
import { VoxxedDaysSharedModule } from '../../shared/shared.module';

const ENTITY_STATES = [
    ...contactRoute,
];

@NgModule({
    imports: [
      VoxxedDaysSharedModule,
      RouterModule.forRoot(ENTITY_STATES, { useHash: true })
    ],
    declarations: [
      ContactComponent,
    ],
    entryComponents: [
      ContactComponent,
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VoxxedDaysContactModule {}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ISpeaker } from './speaker.model';
import { SpeakerService } from './speaker.service';
import { Presentation } from '../presentation';
import { WindowRef } from '../../home/WindowRef';
import { ID } from '../../shared/constants/param.constants';
import { getSpeakerImageURL } from '../../shared/util/speaker-image-util';

@Component({
    selector: 'app-speaker-detail',
    templateUrl: './speaker-detail.component.html'
})
export class SpeakerDetailComponent implements OnInit {

    speaker: ISpeaker;
    presentations: Presentation[] = [];

    constructor(
        private speakerService: SpeakerService,
        private route: ActivatedRoute,
        private winRef: WindowRef
    ) {
    }

    ngOnInit() {
        this.winRef.nativeWindow.webFlow.destroy();
        this.winRef.nativeWindow.webFlow.ready();

        this.route.params.subscribe(params => {
            this.load(params[ID]);
        });
    }

    load(id) {
        this.speakerService.find(id).subscribe(res => {
            this.speaker = res.body;

            this.speakerService.findPresentations(this.speaker.id).subscribe( result => {
                this.presentations = result.body;
            });
        });
    }

    hasTwitterHandle() {
        if (this.speaker != null &&
            (this.speaker.twitterHandle === null ||
             this.speaker.twitterHandle.startsWith('NO_HANDLE'))) {
            return 'hidden';
        }
    }

    getSpeakerAvatarURL() {
        return getSpeakerImageURL(this.speaker, false);
    }

    previousState() {
        window.history.back();
    }
}

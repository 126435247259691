import { Routes } from '@angular/router';

import { ManifestoComponent } from './manifesto.component';

export const manifestoRoute: Routes = [
    {
        path: 'manifesto',
        component: ManifestoComponent,
        data: {
            pageTitle: 'voxxedApp.manifesto.home.title'
        }
    }
];

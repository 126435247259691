<div class="section subpage-section">
    <div class="container w-container">
        <div class="align-left page-title-block">
            <h1 class="page-title">Manifesto</h1>
            <h2 class="page-subtitle">Voxxed Days is an initiative from the people behind Devoxx</h2>
        </div>
        <div class="text-row w-row">
            <div class="text-column-left w-col w-col-9 w-col-stack">
                <h2 class="column-title">What is it?</h2>
                <p class="block-paragraph">Voxxed Days is a series of events, organised locally, where a gap exists in the local technical community conference scene.</p>
                <br>
                <p class="block-paragraph">
                  Voxxed Days was launched following a strong desire for Devoxx style conferences in new locations. As each Devoxx conference needs to run for at least 2 days, and have a minimum of 1,000 attendees, it is not a simple task.
                </p>
                <br>
                <p class="block-paragraph">
                  With Voxxed Days we offer a stepping-stone, a chance to assess demand for an event in your location without too great a risk. If your event is a success, there is potential to turn it into a Devoxx conference in the future.
                </p>
                <p class="block-paragraph">
                  Voxxed Days are community-organised events that share the ethos of Devoxx, with a recognisable brand that can be brought to life by hundreds of individuals all over the world.
                </p>
                <br>
                <p class="block-paragraph">
                  If you have a desire for a technical developer event in your location and are willing to commit a lot of time and energy to make it happen, we can provide Voxxed Days toolbox and personal mentoring that lets you bring your vision to life.
                </p>
                <br>
                <p class="block-paragraph">
                  The Voxxed Days toolbox includes:
                </p>
                  <UL>
                  <LI>Website</LI>
                  <LI>Call for Papers software</LI>
                  <LI>Ticketing platform</LI>
                  <LI>Mobile App</LI>
                  <LI>Sponsorship templates</LI>
                </UL>
                <br>
                <p class="block-paragraph">
                  Together, the creators of Voxxed Days have more than 20 years experience of organising developer events. This experience is shared with Voxxed Days organisers in the form of best practice and ideas to help develop successful local events.
                </p>
                <p class="block-paragraph">
                  For more information, contact us at info@voxxeddays.com
                </p>
            </div>
            <div class="recent-post-column-right w-col w-col-4 w-col-stack">
                <div class="large-column-title">OUR PASSION</div>
                    <div class="blog-list-wrapper w-dyn-list">
                        <div class="blog-list w-dyn-items">
                            <div class="blog-list-item w-dyn-item">
                                <app-event-item [event]="getEvent(0)"></app-event-item>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <div class="text-row w-row">
            <div class="recent-post-column-left w-col w-col-4 w-col-stack">
                <div class="blog-list-wrapper w-dyn-list">
                    <div class="blog-list w-dyn-items">
                        <div class="blog-list-item w-dyn-item">
                            <app-event-item [event]="getEvent(1)"></app-event-item>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-column-right w-col w-col-9 w-col-stack">
                <h2 class="column-title">How Voxxed Days Works?</h2>
                <p class="block-paragraph">
                    Voxxed Days is a franchise model, with a royalty fee paid by the local event organisers which helps us keep Voxxed.com running.
                  A license to operate a Voxxed Days event is issued to an applicant based on their location, and lasts for a minimum period of 1 year and will only be issued to a single applicant per territory.
                </p>
                <br>
                <p class="block-paragraph">
                    If demand is high, an organiser may host a maximum of 4 Voxxed Days events per year within their territory, if agreed with Voxxed in advance.
                </p>
                <br>
                <p class="block-paragraph">
                    Voxxed provides a ‘toolbox’ that enables you to run a successful event. This will include:
                </p>
                <br>
                    <UL>
                        <LI>Website hosting, template, URL and user logins</LI>
                        <LI>Sponsorship prospectus template</LI>
                        <LI>How-to guide – a run-down of what the organisers of Devoxx events have learned over the years from their time organising kick-ass conferences. This includes info on:</LI>
                        <UL>
                            <LI>Program management</LI>
                            <LI>Steering / organisational guidelines</LI>
                            <LI>Operational considerations</LI>
                            <LI>Content guidelines  </LI>
                            <LI>Ticketing guidelines</LI>
                        </UL>
                    </UL>
                <br>
                <p class="block-paragraph">
                    In addition to the ‘Voxxed Days Toolbox’, members of Voxxed staff will be on hand to provide initial mentorship to help you get up and running, as well as on-going support.
                    So if you’ve tried to organise a great developer event in the past but found yourself struggling to get the right content, to sell tickets, attract sponsor support or spread the word, we can offer a helping hand.
                </p>
            </div>
        </div>
        <div class="text-row w-row">
            <div class="text-column-left w-col w-col-9 w-col-stack">
                <h2 class="column-title">Content</h2>
                <p class="block-paragraph">
                    Your event will focus primarily on the 10 topics and theme categories covered by Voxxed.com, with a minimum 85% of all content falling within the categories:
                </p>
                <br>
                <UL>
                    <LI>Server Side Java</LI>
                    <LI>Java SE</LI>
                    <LI>Cloud & Big Data</LI>
                    <LI>Web & HTML</LI>
                    <LI>Mobile</LI>
                    <LI>JVM Languages</LI>
                    <LI>Architecture & Security</LI>
                    <LI>Methodology</LI>
                    <LI>Future Technologies</LI>
                    <LI>Start-up / Venture</LI>
                </UL>
                <br>
                <p class="block-paragraph">
                    You can choose to focus on as many as you wish but a minimum of 4 categories should be covered in your event.
                    We strongly suggest that you create a Program Committee consisting of local people active in the community. A committed Program Committee helps ensure your event has the best possible content available to you.
                </p>
                <br>
                <p class="block-paragraph">
                    Your event should aim to have a solid mix of local talent alongside international ‘Rock Star’ names.
                </p>
                <br>
                <p class="block-paragraph">
                    Conference sessions should be scheduled for a minimum 45-minutes duration.
                </p>
                <br>
                <p class="block-paragraph">
                    <a [routerLink]="['../contact']">Contact Us</a> to receive the full Voxxed Days Manifesto.
                </p>
                <br>
                <a href="#/" class="button outline-button w-button">FUTURE EVENTS</a>
            </div>
            <div class="recent-post-column-right w-col w-col-4 w-col-stack">
                    <div class="blog-list-wrapper w-dyn-list">
                        <div class="blog-list w-dyn-items">
                            <div class="blog-list-item w-dyn-item">
                                <app-event-item [event]="getEvent(2)"></app-event-item>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

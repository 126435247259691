<div class="section subpage-section">
    <div class="container w-container" *ngIf="event">
      <h1>{{event.name}}</h1>
      <h5>{{getEventDate()}}</h5>
      <p>{{event.description}}</p>
      <hr>
      <h3>Speakers</h3>
      <hr>
      <h3>Schedule</h3>
    </div>
</div>

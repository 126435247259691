<div class="section subpage-section">
    <div class="container w-container">
        <div class="page-title-block">
            <div class="page-title-wrapper">
                <h1 class="dynamic-page-title page-title"></h1>
            </div>
            <div class="page-title-wrapper">
                <h1 class="dynamic-subtitle page-subtitle">Upcoming {{categoryName}} events:</h1>
                <h1 class="dynamic-subtitle last page-subtitle"></h1>
            </div>
            <div data-delay="0" class="filter-dropdown fullwidth w-dropdown">
                <div class="filter-dropdown-toggle w-dropdown-toggle">
                    <div class="w-icon-dropdown-toggle"></div>
                    <div class="dropdown-title">Filter by Category</div>
                </div>
                <nav class="dropdown-list-block w-dropdown-list">
                    <div class="collection-list-wrapper w-dyn-list">
                        <div class="dropdown-list w-dyn-items">
                            <div class="dropdown-list-item w-dyn-item"><a [routerLink]="['../devoxx']"
                                                                          class="dropdown-link w-dropdown-link">Devoxx Events</a></div>
                            <div class="dropdown-list-item w-dyn-item"><a [routerLink]="['../voxxed']"
                                                                          class="dropdown-link w-dropdown-link">VoxxxedDays</a></div>
                            <div class="dropdown-list-item w-dyn-item"><a [routerLink]="['../online']"
                                                                          class="dropdown-link w-dropdown-link">Online Events</a></div>
                        </div>
                    </div>
                </nav>
                <img *ngIf="isLoading()" src="https://s3-eu-west-1.amazonaws.com/devoxxians/images/app/Loading.gif" alt="Loading" height="40px" style="margin-top:5px;margin-left:10px">
            </div>
        </div>
        <div class="blog-list-wrapper w-dyn-list">
            <div class="blog-list w-dyn-items w-row">

                <div data-ix="fade-in-on-scroll" class="blog-list-item w-col w-col-6 w-dyn-item">
                    <app-event-item [event]="getEvent(0)"></app-event-item>
                </div>

                <div data-ix="fade-in-on-scroll" class="blog-list-item w-col w-col-6 w-dyn-item">
                    <app-event-item [event]="getEvent(1)"></app-event-item>
                </div>
            </div>
        </div>
        <div class="blog-list-wrapper w-dyn-list">
            <div class="blog-list w-dyn-items w-row">
                <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-3 w-dyn-item">
                    <app-event-item [event]="getEvent(2)" [isSmall]="true"></app-event-item>
                </div>

                <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-3 w-dyn-item">
                    <app-event-item [event]="getEvent(3)" [isSmall]="true"></app-event-item>
                </div>

                <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-3 w-dyn-item">
                    <app-event-item [event]="getEvent(4)" [isSmall]="true"></app-event-item>
                </div>

                <div data-ix="fade-in-on-scroll-3" class="blog-list-item w-col w-col-3 w-dyn-item">
                    <app-event-item [event]="getEvent(5)" [isSmall]="true"></app-event-item>
                </div>
            </div>
        </div>
    </div>
</div>
